import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styles from './GetApi.module.scss';

export type GetApiProps = {}

export interface IQuote {
    id: number,
    body: string,
    author_id: number,
    author: string
}

export default function GetApi({}: GetApiProps) {
    const falseQuote = {
        "quote": "",
        "semQuote": []
    }
    const [quote, setQuote] = useState(falseQuote);
    //use axios to get a random quote from the API "https://stoicquotesapi.com/v1/api/quotes/random"
    const GetApiApi = async () => {
        const response = await axios.get('https://sem-stoicism.frequencydesign.com/stoic-quote');
        console.log(response.data);
        // setQuote(response.data);
        return response.data
    }

    useEffect(() => {
        if (quote.quote.length < 1) {
            GetApiApi().then(data => setQuote(data)); //get a quote when the component loads
        }
    });


    return (
        <div className="GetApi">
            <div className={styles.container}>
                <div className={styles.quote}>{quote.quote}</div>
                <div className={styles.quote}><pre>{JSON.stringify(quote.semQuote, null, 2) }</pre></div>
            </div>
        </div>
    );
}
