import React  from 'react';
import concern from './sustainability-10-00474-g003.png'
import './App.css';
import GetApi from './GetApi/GetApi';
function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={concern} className="App-logo" alt="Model of Concern" />
        <p>

        </p>
        <GetApi />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
      </header>
    </div>
  );
}

export default App;
